<template>
  <Post :column="column" :from-query="params" />
</template>

<script>
  import formItem from './mixins'
  export default {
    mixins: [formItem],
  }
</script>
